import auth from './auth_routes.js'
import store from '@/store'

const jwtISS = 'fhb-loans-commercial'

const businessCreditOwnerRoutes = {
  getToken() {
    return store.getters['businessCreditOwnerApp/jwtToken']
  },
  getRedirect () {
    return '/credit/auth'
  },
  loadBusinessCreditOwnerRoutes(routes) {
    routes.push(
      {
        path: '/credit/auth',
        name: 'Otp Landing',
        component: () => import('../views/business/credit_app_owner/OtpLanding.vue')
      },
      {
        path: '/credit/auth/code',
        name: 'Send Code',
        component: () => import('../views/business/credit_app_owner/SendCode.vue')
      },
      {
        path: '/credit/auth/verification',
        name: 'Code Verification',
        component: () => import('../views/business/credit_app_owner/Verification.vue'),
      },
      {
        path: '/credit/auth/assets',
        name: 'Business Owner Assets',
        component: () => import('../views/business/credit_app_owner/BusinessOwnerAssets.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, localStorage.getItem('token'), this.getRedirect())
        }
      },
      {
        path: '/credit/auth/signature',
        name: 'Business Owner Signature',
        component: () => import('../views/business/credit_app_owner/BusinessOwnerSignature.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, localStorage.getItem('token'), this.getRedirect())
        }
      },
      // {
      //   path: '/credit/auth/submission',
      //   name: 'Business Submission',
      //   component: () => import('../views/business/credit_app_owner/BusinessSubmission.vue'),
      //   beforeEnter: (to, from, next) => {
      //     auth.requireAuth(to, from, next, jwtISS, localStorage.getItem('token'), this.getRedirect())
      //   }
      // },
      {
        path: '/credit/auth/status',
        name: 'Business Status',
        component: () => import('../views/business/credit_app_owner/BusinessStatus.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, localStorage.getItem('token'), this.getRedirect())
        }
      },
    )
    return routes
  }
}

export default businessCreditOwnerRoutes