export default {
  namespaced: true,
  state() {
    return {
      current_page: 0,
      pageInProgress: 0,
      nav: {
        validated_filer_page: 0,
        validated_business_page: 0,
        validated_relationship_page: 0,
        validated_owners_page: 0,
        validated_legal_page: 0,

        validated_assets_page: 0,
        validated_signature_page: 0,
        validated_status_page: 0,
      },
      application_id: '',
      jwt_token: '',

      filer: {
        first_name: "",
        middle_initial: "",
        last_name: "",
        email: "",
        phone_number: "",
        company_position: "",
        agree_to_terms: false,
      },
      business: {
        business_legal_name: "",
        use_alt_business_name: null,
        alt_business_name: "",
        nature_of_business: "",
        company_structure: "",

        federal_tax_id: "",	// if not sole prop
        ssn: "", 		// if sole prop

        established_date: "",
        owned_for_years: "",
        owned_for_months: "",
        annual_gross_revenue: null,
        number_of_employees: null,

        country_of_formation: "",
        delinquent_tax_payments: "",
        lawsuit_or_litigation: "",
        lawsuit_litigation_explanation: "",

        current_address: "",
        current_apt_unit: "",
        current_city: "",
        current_state_territory: "",
        current_zipcode: "",

        mailing_address_same: true,
        mailing_address: "",
        mailing_apt_unit: "",
        mailing_city: "",
        mailing_state_territory: "",
        mailing_zipcode: "",

        phone_number: "",
        census_tract_info: "",	// only required for Guam/Saipan
        num_banking_relations: 1,   // only used to aid in UI
        banking_relations: [
          {
            institution_name: "",
            account_type: "",
            balance: null,
          },
        ],
        num_lender_relations: 1,    // only used to aid in UI
        lender_relations: [
          {
            lender: "",
            loan_type: "",
            maturity_date: "",
            interest_rate: null,	// percent
            monthly_payment: null,
            original_amount: null,
            remaining_balance: null,
          },
        ],
        num_owners: 1,        // only used to aid in UI
        owner: {
          first_name: "",
          middle_initial: "",
          last_name: "",
          email: "",
          phone: "",
          date_of_birth: "",
          company_role: "",
          percentage_owned: 0,
          is_ownership_trust: false,
          resident_status: "",
          address_lived_at_years: 0,
          address_lived_at_months: 0,
          address_ownership: "",
          lived_in_serviceable_location_years: 0,
          lived_in_serviceable_location_months: 0,
          monthly_income: 0,
          bonus_commissions: 0,
          dividends_interest: 0,
          rentals: 0,
          gross_monthly_income: 0,
          assets_in_trust: false,
          assets_in_trust_detail: "",
          is_filer: false,
          ssn: "",
          street_address: "",
          street_address_2: "",
          street_address_city: "",
          street_address_state: "",
          street_address_zip: "",

          mailing_address_same: false,
          mailing_street_address: "",
          mailing_street_address_2: "",
          mailing_street_address_city: "",
          mailing_street_address_state: "",
          mailing_street_address_zip: "",

          ethnicity: "",
          is_women_owned: "",
          
          assets_liabilities: null,

          owner_id: "",
          app_id: "",
          loading: "",
          sent_otp_method: "",
          otp_validated: "",

          owner_is_personal_fi: false,
        },
        
      },
      submissionID: "",
    }
  },
  getters: {
    // Application
    currentPage: (state) => state.current_page,
    pageInProgress: (state) => state.pageInProgress,
    applicationID: (state) => state.application_id,
    jwtToken: (state) => state.jwt_token,
    submissionID:  (state) => state.submissionID,

    // nav
    nav_validated_filer_page: (state) => state.nav.validated_filer_page,
    nav_validated_business_page: (state) => state.nav.validated_business_page,
    nav_validated_relationship_page: (state) => state.nav.validated_relationship_page,
    nav_validated_owners_page: (state) => state.nav.validated_owners_page,
    nav_validated_legal_page: (state) => state.nav.validated_legal_page,

    // owner nav
    nav_validated_assets_page: (state) => state.nav.validated_assets_page,
    nav_validated_signature_page: (state) => state.nav.validated_signature_page,
    nav_validated_status_page: (state) => state.nav.validated_status_page,

    // Filer Info
    filerFirstName: (state) => state.filer.first_name,
    filerMiddleInitial: (state) => state.filer.middle_initial,
    filerLastName: (state) => state.filer.last_name,
    filerEmail: (state) => state.filer.email,
    filerPhoneNumber: (state) => state.filer.phone_number,
    filerCompanyPosition: (state) => state.filer.company_position,
    filerAgreeToTerms: (state) => state.filer.agree_to_terms,

    // Business Info
    businessLegalName: (state) => state.business.business_legal_name,
    businessUseAltName: (state) => state.business.use_alt_business_name,
    businessAltName: (state) => state.business.alt_business_name,
    businessNature: (state) => state.business.nature_of_business,
    companyStructure: (state) => state.business.company_structure,
    federalTaxID: (state) => state.business.federal_tax_id,
    businessSSN: (state) => state.business.ssn,
    establishedDate: (state) => state.business.established_date,
    ownedForYears: (state) => state.business.owned_for_years,
    ownedForMonths: (state) => state.business.owned_for_months,
    annualGrossRevenue: (state) => state.business.annual_gross_revenue,
    numberOfEmployees: (state) => state.business.number_of_employees,
    countryOfFormation: (state) => state.business.country_of_formation,
    delinquentTaxPayments: (state) => state.business.delinquent_tax_payments,
    lawsuitOrLitigation: (state) => state.business.lawsuit_or_litigation,
    lawsuitLitigationExplanation: (state) => state.business.lawsuit_litigation_explanation,
    businessAddress: (state) => state.business.current_address,
    businessAptUnit: (state) => state.business.current_apt_unit,
    businessCity: (state) => state.business.current_city,
    businessStateTerritory: (state) => state.business.current_state_territory,
    businessZipcode: (state) => state.business.current_zipcode,
    businessMailingAddressSame: (state) => state.business.mailing_address_same,
    businessMailingAddress: (state) => state.business.mailing_address,
    businessMailingAptUnit: (state) => state.business.mailing_apt_unit,
    businessMailingCity: (state) => state.business.mailing_city,
    businessMailingStateTerritory: (state) => state.business.mailing_state_territory,
    businessMailingZipcode: (state) => state.business.mailing_zipcode,
    businessPhoneNumber: (state) => state.business.phone_number,
    censusTractInfo: (state) => state.business.census_tract_info,

    // Banking Relations
    businessBankingRelations: (state) => state.business.banking_relations,
    businessNumBankingRelations: (state) => state.business.num_banking_relations,
    businessLenderRelations: (state) => state.business.lender_relations,
    businessNumLenderRelations: (state) => state.business.num_lender_relations,

    // Business Owners
    businessOwners: (state) => state.business.owner,
    numBusinessOwners: (state) => state.business.num_owners,
    ownerId: (state) => state.business.owner.owner_id,
    appId: (state) => state.business.owner.app_id,
    ownerFirstName: (state) => state.business.owner.first_name,
    ownerLastName: (state) => state.business.owner.last_name,
    ownerRole: (state) => state.business.owner.company_position,

    loading: (state) => state.business.owner.loading,
    sentOTPMethod: (state) => state.business.owner.sent_otp_method,
    OtpValidated: (state) => state.business.owner.otp_validated,

    ownerIsPersonalFi: (state) => state.business.owner.owner_is_personal_fi,
  },
  actions: {
    // Application
    setCurrentPage({ commit }, val) {
      commit('setCurrentPage', val)
      commit('setPageInProgress', val)
    },
    setPageInProgress({ commit }, page) {
      commit('setPageInProgress', page)
    },
    setValidatedNavPage({ commit }, page) {
      commit('setValidatedNavPage', page)
    },

    setApplicationID({ commit }, val) {
      commit('setApplicationID', val)
    },
    setJWTToken({ commit }, val) {
      commit('setJWTToken', val)
    },
    setSubmissionID({ commit }, val) {
      commit('setSubmissionID', val)
    },

    // Filer info
    setFilerFirstName({ commit }, val) {
      commit('setFilerFirstName', val)
    },
    setFilerLastName({ commit }, val) {
      commit('setFilerLastName', val)
    },
    setFilerMiddleInitial({ commit }, val) {
      commit('setFilerMiddleInitial', val)
    },
    setFilerEmail({ commit }, val) {
      commit('setFilerEmail', val)
    },
    setFilerPhoneNumber({ commit }, val) {
      commit('setFilerPhoneNumber', val)
    },
    setFilerCompanyPosition({ commit }, val) {
      commit('setFilerCompanyPosition', val)
    },
    setFilerAgreeToTerms({ commit }, val) {
      commit('setFilerAgreeToTerms', val)
    },

    // Business Info
    setBusinessLegalName({ commit }, val) {
      commit('setBusinessLegalName', val)
    },
    setBusinessUseAltName({ commit }, val) {
      commit('setBusinessUseAltName', val)
    },
    setBusinessAltName({ commit }, val) {
      commit('setBusinessAltName', val)
    },
    setBusinessNature({ commit }, val) {
      commit('setBusinessNature', val)
    },
    setCompanyStructure({ commit }, val) {
      commit('setCompanyStructure', val)
    },
    setFederalTaxID({ commit }, val) {
      commit('setFederalTaxID', val)
    },
    setBusinessSSN({ commit }, val) {
      commit('setBusinessSSN', val)
    },
    setEstablishedDate({ commit }, val) {
      commit('setEstablishedDate', val)
    },
    setOwnedForYears({ commit }, val) {
      commit('setOwnedForYears', val)
    },
    setOwnedForMonths({ commit }, val) {
      commit('setOwnedForMonths', val)
    },
    setAnnualGrossRevenue({ commit }, val) {
      commit('setAnnualGrossRevenue', val)
    },
    setNumberOfEmployees({ commit }, val) {
      commit('setNumberOfEmployees', val)
    },
    setCountryOfFormation({ commit }, val) {
      commit('setCountryOfFormation', val)
    },
    setDelinquentTaxPayments({ commit }, val) {
      commit('setDelinquentTaxPayments', val)
    },
    setLawsuitOrLitigation({ commit }, val) {
      commit('setLawsuitOrLitigation', val)
    },
    setLawsuitLitigationExplanation({ commit }, val) {
      commit('setLawsuitLitigationExplanation', val)
    },
    setBusinessAddress({ commit }, val) {
      commit('setBusinessAddress', val)
    },
    setBusinessAptUnit({ commit }, val) {
      commit('setBusinessAptUnit', val)
    },
    setBusinessCity({ commit }, val) {
      commit('setBusinessCity', val)
    },
    setBusinessStateTerritory({ commit }, val) {
      commit('setBusinessStateTerritory', val)
    },
    setBusinessZipcode({ commit }, val) {
      commit('setBusinessZipcode', val)
    },
    setBusinessMailingAddressSame({ commit }, val) {
      commit('setBusinessMailingAddressSame', val)
    },
    setBusinessMailingAddress({ commit }, val) {
      commit('setBusinessMailingAddress', val)
    },
    setBusinessMailingAptUnit({ commit }, val) {
      commit('setBusinessMailingAptUnit', val)
    },
    setBusinessMailingCity({ commit }, val) {
      commit('setBusinessMailingCity', val)
    },
    setBusinessMailingStateTerritory({ commit }, val) {
      commit('setBusinessMailingStateTerritory', val)
    },
    setBusinessMailingZipcode({ commit }, val) {
      commit('setBusinessMailingZipcode', val)
    },
    setBusinessPhoneNumber({ commit }, val) {
      commit('setBusinessPhoneNumber', val)
    },
    setCensusTractInfo({ commit }, val) {
      commit('setCensusTractInfo', val)
    },

    // Banking Relations
    setBusinessBankingRelations({ commit }, val) {
      commit('setBusinessBankingRelations', val)
    },
    appendBusinessBankingRelations({ commit }, val) {
      commit('appendBusinessBankingRelations', val)
    },
    setBusinessLenderRelations({ commit }, val) {
      commit('setBusinessLenderRelations', val)
    },
    appendBusinessLenderRelations({ commit }, val) {
      commit('appendBusinessLenderRelations', val)
    },
    setBusinessNumBankingRelations({ commit }, val) {
      commit('setBusinessNumBankingRelations', val)
    },
    setBusinessNumLenderRelations({ commit }, val) {
      commit('setBusinessNumLenderRelations', val)
    },

    // Business Owners
    setBusinessOwners({ commit }, val) {
      commit('setBusinessOwners', val)
    },
    appendBusinessOwner({ commit }, val) {
      commit('appendBusinessOwner', val)
    },
    setNumBusinessOwners({ commit }, val) {
      commit('setNumBusinessOwners', val)
    },
    setOwnerFirstName({ commit }, val) {
      commit('setOwnerFirstName', val)
    },
    setOwnerLastName({ commit }, val) {
      commit('setOwnerLastName', val)
    },
    setOwnerRole({ commit }, val) {
      commit('setOwnerRole', val)
    },

    setOwnerIsPersonalFi({ commit }, val) {
      commit('setOwnerIsPersonalFi', val)
    },

    // Signatures
    setSignedDate({ commit }, val) {
      commit('setSignedDate', val)
    },

    setOwnerId({ commit }, val) {
      commit('setOwnerId', val)
    },
    setAppId({ commit }, val) {
      commit('setAppId', val)
    },
    updateLoading({ commit }, val) {
      commit('updateLoading', val)
    },
    updateSentOTPMethod({ commit }, val) {
      commit('updateSentOTPMethod', val)
    },
    updateOtpValidated({ commit }, val) {
      commit('updateOtpValidated', val)
    },
  },
  mutations: {
    // Application
    setCurrentPage(state, value) {
      state.current_page = value
    },
    setPageInProgress(state, value) {
      if (value > state.pageInProgress) {
        state.pageInProgress = value;
      }
    },
    setValidatedNavPage(state, page) {
      switch (page.name) {
        case 'filer':
          state.nav.validated_filer_page = page.isValid;
          break;
        case 'business':
          state.nav.validated_business_page = page.isValid;
          break;
        case 'relationship':
          state.nav.validated_relationship_page = page.isValid;
          break;
        case 'owners':
          state.nav.validated_owners_page = page.isValid;
          break;
        case 'legal':
          state.nav.validated_legal_page = page.isValid;
          break;
        case 'owner-assets':
          state.nav.validated_assets_page = page.isValid;
          break;
        case 'owner-signature':
          state.nav.validated_signature_page = page.isValid;
          break;
        case 'owner-status':
          state.nav.validated_status_page = page.isValid;
          break;
      }
    },
    setApplicationID(state, value) {
      state.application_id = value
    },
    setJWTToken(state, value) {
      state.jwt_token = value
    },
    setSubmissionID(state, value) {
      state.submissionID = value
    },

    // Filer Info
    setFilerFirstName(state, value) {
      state.filer.first_name = value;
    },
    setFilerLastName(state, value) {
      state.filer.last_name = value;
    },
    setFilerMiddleInitial(state, value) {
      state.filer.middle_initial = value;
    },
    setFilerEmail(state, value) {
      state.filer.email = value;
    },
    setFilerPhoneNumber(state, value) {
      state.filer.phone_number = value;
    },
    setFilerCompanyPosition(state, value) {
      state.filer.company_position = value;
    },
    setFilerAgreeToTerms(state, value) {
      state.filer.agree_to_terms = value
    },

    // Business Info
    setBusinessLegalName(state, value) {
      state.business.business_legal_name = value
    },
    setBusinessUseAltName(state, value) {
      state.business.use_alt_business_name = value
    },
    setBusinessAltName(state, value) {
      state.business.alt_business_name = value
    },
    setBusinessNature(state, value) {
      state.business.nature_of_business = value
    },
    setCompanyStructure(state, value) {
      state.business.company_structure = value
    },
    setFederalTaxID(state, value) {
      state.business.federal_tax_id = value
    },
    setBusinessSSN(state, value) {
      state.business.ssn = value
    },
    setEstablishedDate(state, value) {
      state.business.established_date = value
    },
    setOwnedForYears(state, value) {
      state.business.owned_for_years = value
    },
    setOwnedForMonths(state, value) {
      state.business.owned_for_months = value
    },
    setAnnualGrossRevenue(state, value) {
      state.business.annual_gross_revenue = value
    },
    setNumberOfEmployees(state, value) {
      state.business.number_of_employees = value
    },
    setCountryOfFormation(state, value) {
      state.business.country_of_formation = value
    },
    setDelinquentTaxPayments(state, value) {
      state.business.delinquent_tax_payments = value
    },
    setLawsuitOrLitigation(state, value) {
      state.business.lawsuit_or_litigation = value
    },
    setLawsuitLitigationExplanation(state, value) {
      state.business.lawsuit_litigation_explanation = value
    },
    setBusinessAddress(state, value) {
      state.business.current_address = value
    },
    setBusinessAptUnit(state, value) {
      state.business.current_apt_unit = value
    },
    setBusinessCity(state, value) {
      state.business.current_city = value
    },
    setBusinessStateTerritory(state, value) {
      state.business.current_state_territory = value
    },
    setBusinessZipcode(state, value) {
      state.business.current_zipcode = value
    },
    setBusinessMailingAddressSame(state, value) {
      state.business.mailing_address_same = value
    },
    setBusinessMailingAddress(state, value) {
      state.business.mailing_address = value
    },
    setBusinessMailingAptUnit(state, value) {
      state.business.mailing_apt_unit = value
    },
    setBusinessMailingCity(state, value) {
      state.business.mailing_city = value
    },
    setBusinessMailingStateTerritory(state, value) {
      state.business.mailing_state_territory = value
    },
    setBusinessMailingZipcode(state, value) {
      state.business.mailing_zipcode = value
    },
    setBusinessPhoneNumber(state, value) {
      state.business.phone_number = value
    },
    setCensusTractInfo(state, value) {
      state.business.census_tract_info = value
    },

    // Banking Relations
    setBusinessBankingRelations(state, value) {
      state.business.banking_relations = value
    },
    appendBusinessBankingRelations(state, value) {
      state.business.banking_relations.push(value)
    },
    setBusinessLenderRelations(state, value) {
      state.business.lender_relations = value
    },
    appendBusinessLenderRelations(state, value) {
      state.business.lender_relations.push(value)
    },
    setBusinessNumBankingRelations(state, value) {
      state.business.num_banking_relations = value
    },
    setBusinessNumLenderRelations(state, value) {
      state.business.num_lender_relations = value
    },

    // Business Owners
    setBusinessOwners(state, value) {
      state.business.owner = value
    },
    appendBusinessOwner(state, value) {
      state.business.owner.push(value)
    },
    setNumBusinessOwners(state, value) {
      state.business.num_owners = value
    },
    setOwnerFirstName(state, value) {
      state.business.owner.first_name = value
    },
    setOwnerLastName(state, value) {
      state.business.owner.last_name = value
    },
    setOwnerRole(state, value) {
      state.business.owner.company_position = value
    },
    setOwnerIsPersonalFi(state, value) {
      state.business.owner.owner_is_personal_fi = value
    },

    // Signatures
    setSignedDate(state, value) {
      state.business.owner.signed_date = value
    },

    setOwnerId(state, value) {
      state.business.owner.owner_id = value
    },
    setAppId(state, value) {
      state.business.owner.app_id = value
    },
    updateLoading(state, value) {
      state.business.owner.loading = value
    },
    updateSentOTPMethod(state, value) {
      state.business.owner.sent_otp_method = value
    },
    updateOtpValidated(state, value) {
      state.business.owner.otp_validated = value
    },
  }
}
