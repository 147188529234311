export default {
  namespaced: true,
  state() {
    return {
      type: '',
      current_page: 0,
      pageInProgress: 0,
      submissionID: "",
      
      document_urls: [],
      additional_document_urls: [],
      uploaded_documents: {},
      uploaded_additional_documents: {},
      uploaded_additional_files: [],
      uploaded_files: [],

      nav: {
        validated_filer_page: 0,
        validated_business_page: 0,
        validated_relationship_page: 0,
        validated_owners_page: 0,
        validated_legal_page: 0,
      },
      application_id: '',
      jwt_token: '',

      filer: {
        loan_amount: "",
        first_name: "",
        middle_initial: "",
        last_name: "",
        email: "",
        phone_number: "",
        company_position: "",
        agree_to_terms: false,
        is_owner: false,
      },
      business: {
        business_legal_name: "",
        use_alt_business_name: null,
        alt_business_name: "",
        nature_of_business: null,
        company_structure: "",

        federal_tax_id: "",	// if not sole prop
        ssn: "", 		// if sole prop

        established_date: "",
        owned_for_years: "",
        owned_for_months: "",
        annual_gross_revenue: null,
        number_of_employees: null,

        country_of_formation: "",
        delinquent_tax_payments: "",
        delinquent_tax_explanation: "",
        lawsuit_or_litigation: "",
        lawsuit_litigation_explanation: "",

        current_address: "",
        current_apt_unit: "",
        current_city: "",
        current_state_territory: "",
        current_zipcode: "",

        mailing_address_same: true,
        mailing_address: "",
        mailing_apt_unit: "",
        mailing_city: "",
        mailing_state_territory: "",
        mailing_zipcode: "",

        phone_number: "",
        census_tract_info: "",	// only required for Guam/Saipan
        num_banking_relations: 1,   // only used to aid in UI
        banking_relations: [
          {
            institution_name: "",
            account_type: "",
            balance: null,
          },
        ],
        num_lender_relations: 1,    // only used to aid in UI
        lender_relations: [
          {
            lender: "",
            loan_type: "",
            maturity_date: "",
            interest_rate: null,	// percent
            monthly_payment: null,
            original_amount: null,
            remaining_balance: null,
            is_secured: null,
            collateral_description: "",
          },
        ],
        num_owners: 1,        // only used to aid in UI
        owners: [
          {
            first_name: "",
            middle_initial: "",
            last_name: "",
            date_of_birth: "",
            ssn: "",
            company_position: "",
            percentage_owned: null,	// percent
            ownership_interest_through_trust: "",
            current_address: "",
            current_apt_unit: "",
            current_city: "",
            current_state_territory: "",
            current_zipcode: "",

            mailing_address_same: true,
            mailing_address: "",
            mailing_apt_unit: "",
            mailing_city: "",
            mailing_state_territory: "",
            mailing_zipcode: "",

            phone_number: "",
            email: "",
            resident_status: "",
            years_at_current: "",
            months_at_current: "",
            years_in_state_territory: "",
            months_in_state_territory: "",
            occupancy_status: "",

            monthly_salary_wages: null,
            bonus_commision: null,
            dividends_interest: null,
            rentals: null,
            gross_monthly_income: null, 	// should be total of previous

            personal_assets_in_trust: "",
            asset_explanation: "",

            signature: "",
            signed_date: "",

            initials: "",

            financial_condition: [
              {
                item_type: "",
                institution_name: "",
                amount: null,
                balance: null,
                loans_on_insurance: "",
                auto_year: "",
                auto_make_model: "",
                monthly_payment: null,
                property_owner: "",
                property_location: "",
                rental_income: "",
              }
            ],

          owner_id: "",
          app_id: "",
          loading: "",
          sent_otp_method: "",
          otp_validated: "",
          }
        ]
      }
    }
  },
  getters: {
    submissionID: (state) => state.submissionID,
    // Application
    type: (state) => state.type,
    currentPage: (state) => state.current_page,
    pageInProgress: (state) => state.pageInProgress,
    applicationID: (state) => state.application_id,
    jwtToken: (state) => state.jwt_token,

    document_urls: (state) => state.document_urls,
    additional_document_urls: (state) => state.additional_document_urls,
    uploaded_documents: (state) => state.uploaded_documents,
    uploaded_files: (state) => state.uploaded_files,
    uploaded_additional_files: (state) => state.uploaded_additional_files,
    uploaded_additional_documents: (state) => state.uploaded_additional_documents,
    // nav
    nav_validated_filer_page: (state) => state.nav.validated_filer_page,
    nav_validated_business_page: (state) => state.nav.validated_business_page,
    nav_validated_relationship_page: (state) => state.nav.validated_relationship_page,
    nav_validated_owners_page: (state) => state.nav.validated_owners_page,
    nav_validated_legal_page: (state) => state.nav.validated_legal_page,

    // Filer Info
    loanAmount: (state) => state.filer.loan_amount,
    filerFirstName: (state) => state.filer.first_name,
    filerMiddleInitial: (state) => state.filer.middle_initial,
    filerLastName: (state) => state.filer.last_name,
    filerEmail: (state) => state.filer.email,
    filerPhoneNumber: (state) => state.filer.phone_number,
    filerCompanyPosition: (state) => state.filer.company_position,
    filerAgreeToTerms: (state) => state.filer.agree_to_terms,
    filerIsOwner: (state) => state.filer.is_owner,

    // Business Info
    businessLegalName: (state) => state.business.business_legal_name,
    businessUseAltName: (state) => state.business.use_alt_business_name,
    businessAltName: (state) => state.business.alt_business_name,
    businessNature: (state) => state.business.nature_of_business,
    companyStructure: (state) => state.business.company_structure,
    federalTaxID: (state) => state.business.federal_tax_id,
    businessSSN: (state) => state.business.ssn,
    establishedDate: (state) => state.business.established_date,
    ownedForYears: (state) => state.business.owned_for_years,
    ownedForMonths: (state) => state.business.owned_for_months,
    annualGrossRevenue: (state) => state.business.annual_gross_revenue,
    numberOfEmployees: (state) => state.business.number_of_employees,
    countryOfFormation: (state) => state.business.country_of_formation,
    delinquentTaxPayments: (state) => state.business.delinquent_tax_payments,
    delinquentTaxExplanation: (state) => state.business.delinquent_tax_explanation,
    lawsuitOrLitigation: (state) => state.business.lawsuit_or_litigation,
    lawsuitLitigationExplanation: (state) => state.business.lawsuit_litigation_explanation,
    businessAddress: (state) => state.business.current_address,
    businessAptUnit: (state) => state.business.current_apt_unit,
    businessCity: (state) => state.business.current_city,
    businessStateTerritory: (state) => state.business.current_state_territory,
    businessZipcode: (state) => state.business.current_zipcode,
    businessMailingAddressSame: (state) => state.business.mailing_address_same,
    businessMailingAddress: (state) => state.business.mailing_address,
    businessMailingAptUnit: (state) => state.business.mailing_apt_unit,
    businessMailingCity: (state) => state.business.mailing_city,
    businessMailingStateTerritory: (state) => state.business.mailing_state_territory,
    businessMailingZipcode: (state) => state.business.mailing_zipcode,
    businessPhoneNumber: (state) => state.business.phone_number,
    censusTractInfo: (state) => state.business.census_tract_info,

    // Banking Relations
    businessBankingRelations: (state) => state.business.banking_relations,
    businessNumBankingRelations: (state) => state.business.num_banking_relations,
    businessLenderRelations: (state) => state.business.lender_relations,
    businessNumLenderRelations: (state) => state.business.num_lender_relations,

    // Business Owners
    businessOwners: (state) => state.business.owners,
    numBusinessOwners: (state) => state.business.num_owners,
    ownerId: (state) => state.business.owners.owner_id,
    appId: (state) => state.business.owners.app_id,

    loading: (state) => state.business.owners.loading,
    sentOTPMethod: (state) => state.business.owners.sent_otp_method,
    OtpValidated: (state) => state.business.owners.otp_validated,

  },
  actions: {
    // Application
    setType({ commit }, val) {
      commit('setType', val)
    },
    setCurrentPage({ commit }, val) {
      commit('setCurrentPage', val)
      commit('setPageInProgress', val)
    },
    setPageInProgress({ commit }, page) {
      commit('setPageInProgress', page)
    },
    setValidatedNavPage({ commit }, page) {
      commit('setValidatedNavPage', page)
    },

    setApplicationID({ commit }, val) {
      commit('setApplicationID', val)
    },
    setJWTToken({ commit }, val) {
      commit('setJWTToken', val)
    },
    setSubmissionID({ commit }, val) {
      commit('setSubmissionID', val)
    },

    setDocumentUrls({ commit }, urls) {
      commit('setDocumentUrls', urls)
    },
    setAdditionalDocumentUrls({ commit }, urls) {
      commit('setAdditionalDocumentUrls', urls)
    },

    setDocumentUpload({ commit }, value) {
      commit('setDocumentUpload', value)
    },
    setAdditionalDocumentUpload({ commit }, value) {
      commit('setAdditionalDocumentUpload', value)
    },
    setUploadedDocuments({ commit }, value) {
      commit('setUploadedDocuments', value)
    },
    setUploadedDocumentsInit({ commit }, value) {
      commit('setUploadedDocumentsInit', value)
    },
    setUploadedDocumentsReset({ commit }, value) {
      commit('setUploadedDocumentsReset', value)
    },
    setUploadedDocumentsReason({ commit }, value) {
      commit('setUploadedDocumentsReason', value)
    },
    setUploadedDocumentsUploadAttempt({ commit }, value) {
      commit('setUploadedDocumentsUploadAttempt', value)
    },
    setUploadedDocumentsUploadFailed({ commit }, value) {
      commit('setUploadedDocumentsUploadFailed', value)
    },
    setUploadedDocumentsUploadValid({ commit }, value) {
      commit('setUploadedDocumentsUploadValid', value)
    },
    setUploadedFiles({ commit }, value) {
      commit('setUploadedFiles', value)
    },

    setUploadedAdditionalFiles({ commit }, value) {
      commit('setUploadedAdditionalFiles', value)
    },
    setUploadedAdditionalDocuments({ commit }, value) {
      commit('setUploadedAdditionalDocuments', value)
    },
    setUploadedAdditionalDocumentsInit({ commit }, value) {
      commit('setUploadedAdditionalDocumentsInit', value)
    },
    setUploadedAdditionalDocumentsReset({ commit }, value) {
      commit('setUploadedAdditionalDocumentsReset', value)
    },
    setUploadedAdditionalDocumentsReason({ commit }, value) {
      commit('setUploadedAdditionalDocumentsReason', value)
    },
    setUploadedAdditionalDocumentsUploadAttempt({ commit }, value) {
      commit('setUploadedAdditionalDocumentsUploadAttempt', value)
    },
    setUploadedAdditionalDocumentsUploadFailed({ commit }, value) {
      commit('setUploadedAdditionalDocumentsUploadFailed', value)
    },
    setUploadedAdditionalDocumentsUploadValid({ commit }, value) {
      commit('setUploadedAdditionalDocumentsUploadValid', value)
    },
    // Filer info
    setLoanAmount({ commit }, val) {
      commit('setLoanAmount', val)
    },
    setFilerFirstName({ commit }, val) {
      commit('setFilerFirstName', val)
    },
    setFilerLastName({ commit }, val) {
      commit('setFilerLastName', val)
    },
    setFilerMiddleInitial({ commit }, val) {
      commit('setFilerMiddleInitial', val)
    },
    setFilerEmail({ commit }, val) {
      commit('setFilerEmail', val)
    },
    setFilerPhoneNumber({ commit }, val) {
      commit('setFilerPhoneNumber', val)
    },
    setFilerCompanyPosition({ commit }, val) {
      commit('setFilerCompanyPosition', val)
    },
    setFilerAgreeToTerms({ commit }, val) {
      commit('setFilerAgreeToTerms', val)
    },
    setFilerIsOwner({ commit }, val) {
      commit('setFilerIsOwner', val)
    },

    // Business Info
    setBusinessLegalName({ commit }, val) {
      commit('setBusinessLegalName', val)
    },
    setBusinessUseAltName({ commit }, val) {
      commit('setBusinessUseAltName', val)
    },
    setBusinessAltName({ commit }, val) {
      commit('setBusinessAltName', val)
    },
    setBusinessNature({ commit }, val) {
      commit('setBusinessNature', val)
    },
    setCompanyStructure({ commit }, val) {
      commit('setCompanyStructure', val)
    },
    setFederalTaxID({ commit }, val) {
      commit('setFederalTaxID', val)
    },
    setBusinessSSN({ commit }, val) {
      commit('setBusinessSSN', val)
    },
    setEstablishedDate({ commit }, val) {
      commit('setEstablishedDate', val)
    },
    setOwnedForYears({ commit }, val) {
      commit('setOwnedForYears', val)
    },
    setOwnedForMonths({ commit }, val) {
      commit('setOwnedForMonths', val)
    },
    setAnnualGrossRevenue({ commit }, val) {
      commit('setAnnualGrossRevenue', val)
    },
    setNumberOfEmployees({ commit }, val) {
      commit('setNumberOfEmployees', val)
    },
    setCountryOfFormation({ commit }, val) {
      commit('setCountryOfFormation', val)
    },
    setDelinquentTaxPayments({ commit }, val) {
      commit('setDelinquentTaxPayments', val)
    },
    setDelinquentTaxExplanation({ commit }, val) {
      commit('setDelinquentTaxExplanation', val)
    },
    setLawsuitOrLitigation({ commit }, val) {
      commit('setLawsuitOrLitigation', val)
    },
    setLawsuitLitigationExplanation({ commit }, val) {
      commit('setLawsuitLitigationExplanation', val)
    },
    setBusinessAddress({ commit }, val) {
      commit('setBusinessAddress', val)
    },
    setBusinessAptUnit({ commit }, val) {
      commit('setBusinessAptUnit', val)
    },
    setBusinessCity({ commit }, val) {
      commit('setBusinessCity', val)
    },
    setBusinessStateTerritory({ commit }, val) {
      commit('setBusinessStateTerritory', val)
    },
    setBusinessZipcode({ commit }, val) {
      commit('setBusinessZipcode', val)
    },
    setBusinessMailingAddressSame({ commit }, val) {
      commit('setBusinessMailingAddressSame', val)
    },
    setBusinessMailingAddress({ commit }, val) {
      commit('setBusinessMailingAddress', val)
    },
    setBusinessMailingAptUnit({ commit }, val) {
      commit('setBusinessMailingAptUnit', val)
    },
    setBusinessMailingCity({ commit }, val) {
      commit('setBusinessMailingCity', val)
    },
    setBusinessMailingStateTerritory({ commit }, val) {
      commit('setBusinessMailingStateTerritory', val)
    },
    setBusinessMailingZipcode({ commit }, val) {
      commit('setBusinessMailingZipcode', val)
    },
    setBusinessPhoneNumber({ commit }, val) {
      commit('setBusinessPhoneNumber', val)
    },
    setCensusTractInfo({ commit }, val) {
      commit('setCensusTractInfo', val)
    },

    // Banking Relations
    setBusinessBankingRelations({ commit }, val) {
      commit('setBusinessBankingRelations', val)
    },
    appendBusinessBankingRelations({ commit }, val) {
      commit('appendBusinessBankingRelations', val)
    },
    setBusinessLenderRelations({ commit }, val) {
      commit('setBusinessLenderRelations', val)
    },
    appendBusinessLenderRelations({ commit }, val) {
      commit('appendBusinessLenderRelations', val)
    },
    setBusinessNumBankingRelations({ commit }, val) {
      commit('setBusinessNumBankingRelations', val)
    },
    setBusinessNumLenderRelations({ commit }, val) {
      commit('setBusinessNumLenderRelations', val)
    },

    // Business Owners
    setBusinessOwners({ commit }, val) {
      commit('setBusinessOwners', val)
    },
    appendBusinessOwner({ commit }, val) {
      commit('appendBusinessOwner', val)
    },
    setNumBusinessOwners({ commit }, val) {
      commit('setNumBusinessOwners', val)
    },

    // Signatures
    setSignedDate({ commit }, i, val) {
      commit('setSignedDate', i, val)
    },

    setOwnerId({ commit }, val) {
      commit('setOwnerId', val)
    },
    setAppId({ commit }, val) {
      commit('setAppId', val)
    },
    updateLoading({ commit }, val) {
      commit('updateLoading', val)
    },
    updateSentOTPMethod({ commit }, val) {
      commit('updateSentOTPMethod', val)
    },
    updateOtpValidated({ commit }, val) {
      commit('updateOtpValidated', val)
    },
  },
  mutations: {
    // Application
    setType(state, value) {
      state.type = value
    },
    setSubmissionID(state, value) {
      state.submissionID = value
    },
    setCurrentPage(state, value) {
      state.current_page = value
    },
    setPageInProgress(state, value) {
      if (value > state.pageInProgress) {
        state.pageInProgress = value;
      }
    },
    setDocumentUrls(state, value) {
      state.document_urls = value
    },
    setAdditionalDocumentUrls(state, value) {
      state.additional_document_urls = value
    },
    setDocumentUpload(state, value) {
      state.document_upload_setup = value;
    },

    setAdditionalDocumentUpload(state, value) {
      state.additional_document_upload_setup = value;
    },
    setUploadedDocuments(state, value) {
      state.uploaded_documents[value.index] = {};
      state.uploaded_documents[value.index].file = value.file;
      state.uploaded_documents[value.index].hovered = false;
      state.uploaded_documents[value.index].url = value.url;
    },
    setUploadedDocumentsInit(state, value) {
      state.uploaded_documents[value] = {};
    },
    setUploadedDocumentsReset(state, value) {
      state.uploaded_documents[value.index] = null;
    },
    setUploadedDocumentsReason(state, value) {
      state.uploaded_documents[value.index].reason = value.reason;
    },
    setUploadedDocumentsUploadAttempt(state, value) {
      state.uploaded_documents[value.index].uploadAttempt = value.value;
    },
    setUploadedDocumentsUploadFailed(state, value) {
      state.uploaded_documents[value.index].uploadFailed = value.value;
    },
    setUploadedDocumentsUploadValid(state, value) {
      state.uploaded_documents[value.index].valid = value.value;
    },
    setUploadedFiles(state, value) {
      state.uploaded_files = value;
    },
    setUploadedAdditionalFiles(state, value) {
      state.uploaded_additional_files = value;
    },
    setUploadedAdditionalDocuments(state, value) {
      state.uploaded_additional_documents[value.index] = {};
      state.uploaded_additional_documents[value.index].file = value.file;
      state.uploaded_additional_documents[value.index].hovered = false;
      state.uploaded_additional_documents[value.index].url = value.url;
    },
    setUploadedAdditionalDocumentsInit(state, value) {
      state.uploaded_additional_documents[value] = {};
    },
    setUploadedAdditionalDocumentsReset(state, value) {
      state.uploaded_additional_documents[value.index] = null;
    },
    setUploadedAdditionalDocumentsReason(state, value) {
      state.uploaded_additional_documents[value.index].reason = value.reason;
    },
    setUploadedAdditionalDocumentsUploadAttempt(state, value) {
      state.uploaded_additional_documents[value.index].uploadAttempt = value.value;
    },
    setUploadedAdditionalDocumentsUploadFailed(state, value) {
      state.uploaded_additional_documents[value.index].uploadFailed = value.value;
    },
    setUploadedAdditionalDocumentsUploadValid(state, value) {
      state.uploaded_additional_documents[value.index].valid = value.value;
    },
    
    setValidatedNavPage(state, page) {
      switch (page.name) {
        case 'filer':
          state.nav.validated_filer_page = page.isValid;
          break;
        case 'business':
          state.nav.validated_business_page = page.isValid;
          break;
        case 'relationship':
          state.nav.validated_relationship_page = page.isValid;
          break;
        case 'owners':
          state.nav.validated_owners_page = page.isValid;
          break;
        case 'document':
          state.nav.validated_document_page = page.isValid;
          break;
        case 'legal':
          state.nav.validated_legal_page = page.isValid;
          break;
      }
    },
    setApplicationID(state, value) {
      state.application_id = value
    },
    setJWTToken(state, value) {
      state.jwt_token = value
    },

    // Filer Info
    setLoanAmount(state, value) {
      state.filer.loan_amount = value;
    },
    setFilerFirstName(state, value) {
      state.filer.first_name = value;
    },
    setFilerLastName(state, value) {
      state.filer.last_name = value;
    },
    setFilerMiddleInitial(state, value) {
      state.filer.middle_initial = value;
    },
    setFilerEmail(state, value) {
      state.filer.email = value;
    },
    setFilerPhoneNumber(state, value) {
      state.filer.phone_number = value;
    },
    setFilerCompanyPosition(state, value) {
      state.filer.company_position = value;
    },
    setFilerAgreeToTerms(state, value) {
      state.filer.agree_to_terms = value
    },
    setFilerIsOwner(state, value) {
      state.filer.is_owner = value
    },

    // Business Info
    setBusinessLegalName(state, value) {
      state.business.business_legal_name = value
    },
    setBusinessUseAltName(state, value) {
      state.business.use_alt_business_name = value
    },
    setBusinessAltName(state, value) {
      state.business.alt_business_name = value
    },
    setBusinessNature(state, value) {
      state.business.nature_of_business = value
    },
    setCompanyStructure(state, value) {
      state.business.company_structure = value
    },
    setFederalTaxID(state, value) {
      state.business.federal_tax_id = value
    },
    setBusinessSSN(state, value) {
      state.business.ssn = value
    },
    setEstablishedDate(state, value) {
      state.business.established_date = value
    },
    setOwnedForYears(state, value) {
      state.business.owned_for_years = value
    },
    setOwnedForMonths(state, value) {
      state.business.owned_for_months = value
    },
    setAnnualGrossRevenue(state, value) {
      state.business.annual_gross_revenue = value
    },
    setNumberOfEmployees(state, value) {
      state.business.number_of_employees = value
    },
    setCountryOfFormation(state, value) {
      state.business.country_of_formation = value
    },
    setDelinquentTaxPayments(state, value) {
      state.business.delinquent_tax_payments = value
    },
    setLawsuitOrLitigation(state, value) {
      state.business.lawsuit_or_litigation = value
    },
    setLawsuitLitigationExplanation(state, value) {
      state.business.lawsuit_litigation_explanation = value
    },
    setDelinquentTaxExplanation(state, value) {
      state.business.delinquent_tax_explanation = value
    },
    setBusinessAddress(state, value) {
      state.business.current_address = value
    },
    setBusinessAptUnit(state, value) {
      state.business.current_apt_unit = value
    },
    setBusinessCity(state, value) {
      state.business.current_city = value
    },
    setBusinessStateTerritory(state, value) {
      state.business.current_state_territory = value
    },
    setBusinessZipcode(state, value) {
      state.business.current_zipcode = value
    },
    setBusinessMailingAddressSame(state, value) {
      state.business.mailing_address_same = value
    },
    setBusinessMailingAddress(state, value) {
      state.business.mailing_address = value
    },
    setBusinessMailingAptUnit(state, value) {
      state.business.mailing_apt_unit = value
    },
    setBusinessMailingCity(state, value) {
      state.business.mailing_city = value
    },
    setBusinessMailingStateTerritory(state, value) {
      state.business.mailing_state_territory = value
    },
    setBusinessMailingZipcode(state, value) {
      state.business.mailing_zipcode = value
    },
    setBusinessPhoneNumber(state, value) {
      state.business.phone_number = value
    },
    setCensusTractInfo(state, value) {
      state.business.census_tract_info = value
    },

    // Banking Relations
    setBusinessBankingRelations(state, value) {
      state.business.banking_relations = value
    },
    appendBusinessBankingRelations(state, value) {
      state.business.banking_relations.push(value)
    },
    setBusinessLenderRelations(state, value) {
      state.business.lender_relations = value
    },
    appendBusinessLenderRelations(state, value) {
      state.business.lender_relations.push(value)
    },
    setBusinessNumBankingRelations(state, value) {
      state.business.num_banking_relations = value
    },
    setBusinessNumLenderRelations(state, value) {
      state.business.num_lender_relations = value
    },

    // Business Owners
    setBusinessOwners(state, value) {
      state.business.owners = value
    },
    appendBusinessOwner(state, value) {
      state.business.owners.push(value)
    },
    setNumBusinessOwners(state, value) {
      state.business.num_owners = value
    },

    // Signatures
    setSignedDate(state, i, value) {
      state.business.owners[i].signed_date = value
    },

    setOwnerId(state, value) {
      state.business.owners.owner_id = value
    },
    setAppId(state, value) {
      state.business.owners.app_id = value
    },
    updateLoading(state, value) {
      state.business.owners.loading = value
    },
    updateSentOTPMethod(state, value) {
      state.business.owners.sent_otp_method = value
    },
    updateOtpValidated(state, value) {
      state.business.owners.otp_validated = value
    },
  }
}
