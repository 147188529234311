export default {
  namespaced: true,
  state() {
    return {
      count: 0,
      currentPage: 0,
      pageInProgress: 0,
      debug: false,

      nav: {
        validated_personal_page: 0,
        validated_assets_page: 0,
        validated_document_page: 0,
      },
      code: '',
      code_type: '',
      application_id: '',
      jwt_token: '',
      submission_id: '',

      type: 'commerical-loan',

      document_urls: [],
      additional_document_urls: [],
      uploaded_documents: {},
      uploaded_additional_documents: {},
      uploaded_additional_files: [],
      uploaded_files: [],

      business_credit_id: '',
      business_credit_owner_id: '',

      //1. Personal information
      applicant: {
        id: '',
        email: '',
        first_name: '',
        middle_initial: '',
        last_name: '',
        phone_number: '',
        employer: '',
        occupation: '',
        ssn: '',
        current_address: '',
        current_apt_unit: '',
        current_city: '',
        current_state_or_territory: '',
        current_zipcode: '',
        signature: '',
        initials: '',
      },

      assets: {
        cash_in_fhb: '',
        cash_in_other: '',
        account_receivable: '',
        due_from_friends: '',
        notes_receivable: '',
        real_estate_residence: '',
        real_estate_investments: '',
        marketable_securities: '',
        retirement_accounts: '',
        life_insurance: '',
        investment_in_business: '',
        other_investments: '',
        other_investments_desc: '',
        personal_property: '',
        autos: '',
        other_assets: '',
        other_assets_desc: '',
        total_assets: '',
      },

      loans: {
        loans_to_fhb: '',
        loans_to_other: '',
        charge_cards: '',
        accounts_payable: '',
        due_to_friends: '',
        notes_payable: '',
        real_estate_mortgages: '',
        income_taxes: '',
        other_taxes: '',
        life_insurance_loan: '',
        other_liabilities: '',
        auto_loans: '',
        total_liabilities: '',
        net_worth: '',
        total_loans: '',
      },

      business_association: '',
      business_association_indicator: false,
      business_association_reason: '',

      executed_will: '',
      executed_will_indicator: false,

      trust: '',
      trust_indicator: false,

      asset_trust: '',
      asset_trust_indicator: false,
      asset_trust_title: '',

      contingent_liabilities: '',
      contingent_liabilities_indicator: false,
      contingent_liabilities_reason: '',

      other_bank: '',
      other_bank_indicator: false,
      other_bank_nature: null,

      other_claims: '',
      other_claims_indicator: false,
      other_claims_reason: '',

      bankruptcy: '',
      bankruptcy_indicator: false,
      bankruptcy_reason: '',

      income: {
        salary: '',
        salary_desc: '',
        interest: '',
        securities: '',
        rental: '',
        net_income: '',
        other: '',
        other_desc: '',
        total: '',

      },

      expense: {
        mortgage_residence: '',
        mortgage_other: '',
        installment: '',
        income_tax: '',
        other: '',
        total: '',
      },

      num_of_sch1: 1,
      schedule1: [
        {
          debtor_name: '',
          amount_due: '',
          payable: '',
          maturity: '',
          remarks: '',
        }
      ],
      num_of_sch2: 1,
      schedule2: [
        {
          property_addr: '',
          date_acquired: '',
          monthly_rental_inc: '',
          title: '',
          valuation: '',
          balance_owed: '',
          is_primary_residence: ''
        }
      ],
      num_of_sch3: 1,
      schedule3: [
        {
          payable: '',
          balance_owed: '',
          interest_rate: '',
          maturity: '',
          monthly_payment: '',
        }
      ],
      num_of_sch4: 1,
      schedule4: [
        {
          type: '',
          stock_shares: '',
          value_carried: '',
          listed_mkt: '',
          listed_mkt_amt: '',
          unlisted_value: '',
          unlisted_value_amt: '',
          unlisted_value_yrly_inc: '',
        }
      ],
      num_of_sch5: 1,
      schedule5: [
        {
          beneficiary: '',
          policy_amt: '',
          cash: '',
          loan: '',
          net_cash: '',
        }
      ],
      sch2ValuationReason: '',
      sch3DelinquentPaymentReason: '',
      sch3DelinquentTaxReason: '',
      sch3UnrecordedClaim: '',
      sch4HeldSecurities: '',



    }
  },
  getters: {
    debug: (state) => state.debug,
    currentPage: (state) => state.currentPage,
    pageInProgress: (state) => state.pageInProgress,
    application_id: (state) => state.application_id,
    jwt_token: (state) => state.jwt_token,
    type: (state) => state.type,
    code: (state) => state.code,
    code_type: (state) => state.code_type,
    submission_id: (state) => state.submission_id,

    // nav
    nav_validated_personal_page: (state) => state.nav.validated_personal_page,
    nav_validated_business_page: (state) => state.nav.validated_business_page,
    nav_validated_document_page: (state) => state.nav.validated_document_page,

    email: (state) => state.email,

    document_urls: (state) => state.document_urls,
    additional_document_urls: (state) => state.additional_document_urls,
    uploaded_documents: (state) => state.uploaded_documents,
    uploaded_files: (state) => state.uploaded_files,
    uploaded_additional_files: (state) => state.uploaded_additional_files,
    uploaded_additional_documents: (state) => state.uploaded_additional_documents,

    applicant_id: (state) => state.applicant.id,
    applicant_first_name: (state) => state.applicant.first_name,
    applicant_middle_initial: (state) => state.applicant.middle_initial,
    applicant_last_name: (state) => state.applicant.last_name,
    applicant_email: (state) => state.applicant.email,
    applicant_employer: (state) => state.applicant.employer,
    applicant_occupation: (state) => state.applicant.occupation,
    applicant_phone_number: (state) => state.applicant.phone_number,
    applicant_ssn: (state) => state.applicant.ssn,
    applicant_current_address: (state) => state.applicant.current_address,
    applicant_current_apt_unit: (state) => state.applicant.current_apt_unit,
    applicant_current_city: (state) => state.applicant.current_city,
    applicant_current_state_or_territory: (state) => state.applicant.current_state_or_territory,
    applicant_current_zipcode: (state) => state.applicant.current_zipcode,
    applicant_signature: (state) => state.applicant.signature,
    applicant_initials: (state) => state.applicant.initials,

    assets_cash_in_fhb: (state) => state.assets.cash_in_fhb,
    assets_cash_in_other: (state) => state.assets.cash_in_other,
    assets_account_receivable: (state) => state.assets.account_receivable,
    assets_due_from_friends: (state) => state.assets.due_from_friends,
    assets_notes_receivable: (state) => state.assets.notes_receivable,
    assets_real_estate_residence: (state) => state.assets.real_estate_residence,
    assets_real_estate_investments: (state) => state.assets.real_estate_investments,
    assets_marketable_securities: (state) => state.assets.marketable_securities,
    assets_retirement_accounts: (state) => state.assets.retirement_accounts,
    assets_life_insurance: (state) => state.assets.life_insurance,
    assets_investment_in_business: (state) => state.assets.investment_in_business,
    assets_other_investments: (state) => state.assets.other_investments,
    assets_other_investments_desc: (state) => state.assets.other_investments_desc,
    assets_personal_property: (state) => state.assets.personal_property,
    assets_autos: (state) => state.assets.autos,
    assets_other_assets: (state) => state.assets.other_assets,
    assets_other_assets_desc: (state) => state.assets.other_assets_desc,
    assets_total_assets: (state) => state.assets.total_assets,

    loans_to_fhb: (state) => state.loans.loans_to_fhb,
    loans_to_other: (state) => state.loans.loans_to_other,
    loans_charge_cards: (state) => state.loans.charge_cards,
    loans_accounts_payable: (state) => state.loans.accounts_payable,
    loans_due_to_friends: (state) => state.loans.due_to_friends,
    loans_notes_payable: (state) => state.loans.notes_payable,
    loans_real_estate_mortgages: (state) => state.loans.real_estate_mortgages,
    loans_income_taxes: (state) => state.loans.income_taxes,
    loans_other_taxes: (state) => state.loans.other_taxes,
    loans_life_insurance_loan: (state) => state.loans.life_insurance_loan,
    loans_other_liabilities: (state) => state.loans.other_liabilities,
    loans_auto_loans: (state) => state.loans.auto_loans,
    loans_total_liabilities: (state) => state.loans.total_liabilities,
    loans_net_worth: (state) => state.loans.net_worth,
    loans_total_loans: (state) => state.loans.total_loans,

    business_association: (state) => state.business_association,
    business_association_indicator: (state) => state.business_association_indicator,
    business_association_reason: (state) => state.business_association_reason,

    executed_will: (state) => state.executed_will,
    executed_will_indicator: (state) => state.executed_will_indicator,

    trust: (state) => state.trust,
    trust_indicator: (state) => state.trust_indicator,

    asset_trust: (state) => state.asset_trust,
    asset_trust_indicator: (state) => state.asset_trust_indicator,
    asset_trust_title: (state) => state.asset_trust_title,

    contingent_liabilities: (state) => state.contingent_liabilities,
    contingent_liabilities_indicator: (state) => state.contingent_liabilities_indicator,
    contingent_liabilities_reason: (state) => state.contingent_liabilities_reason,

    other_bank: (state) => state.other_bank,
    other_bank_indicator: (state) => state.other_bank_indicator,
    other_bank_nature: (state) => state.other_bank_nature,

    other_claims: (state) => state.other_claims,
    other_claims_indicator: (state) => state.other_claims_indicator,
    other_claims_reason: (state) => state.other_claims_reason,

    bankruptcy: (state) => state.bankruptcy,
    bankruptcy_indicator: (state) => state.bankruptcy_indicator,
    bankruptcy_reason: (state) => state.bankruptcy_reason,

    income_salary: (state) => state.income.salary,
    income_salary_desc: (state) => state.income.salary_desc,
    income_interest: (state) => state.income.interest,
    mortgage_residence: (state) => state.expense.mortgage_residence,
    mortgage_other: (state) => state.expense.mortgage_other,
    installment: (state) => state.expense.installment,
    income_securities: (state) => state.income.securities,
    income_tax: (state) => state.expense.income_tax,
    income_rental: (state) => state.income.rental,
    expense_other: (state) => state.expense.other,
    income_net_income: (state) => state.income.net_income,
    income_other: (state) => state.income.other,
    income_other_desc: (state) => state.income.other_desc,
    income_total: (state) => state.income.total,
    expense_total: (state) => state.expense.total,

    num_of_sch1: (state) => state.num_of_sch1,
    schedule1: (state) => state.schedule1,
    sch1_debtor_name: (state) => state.schedule1.debtor_name,
    sch1_amt_due: (state) => state.schedule1.amount_due,
    sch1_payable: (state) => state.schedule1.payable,
    sch1_maturity: (state) => state.schedule1.maturity,
    sch1_remarks: (state) => state.schedule1.remarks,

    num_of_sch2: (state) => state.num_of_sch2,
    schedule2: (state) => state.schedule2,
    sch2_property_addr: (state) => state.schedule2.property_addr,
    sch2_date_acquired: (state) => state.schedule2.date_acquired,
    sch2_monthly_rental_inc: (state) => state.schedule2.monthly_rental_inc,
    sch2_title: (state) => state.schedule2.title,
    sch2_valuation: (state) => state.schedule2.valuation,
    sch2_balance_owed: (state) => state.schedule2.balance_owed,

    num_of_sch3: (state) => state.num_of_sch3,
    schedule3: (state) => state.schedule3,
    sch3_payable: (state) => state.schedule3.payable,
    sch3_balance_owed: (state) => state.schedule3.balance_owed,
    sch3_interest_rate: (state) => state.schedule3.interest_rate,
    sch3_maturity: (state) => state.schedule3.maturity,
    sch3_monthly_payment: (state) => state.schedule3.monthly_payment,

    num_of_sch4: (state) => state.num_of_sch4,
    schedule4: (state) => state.schedule4,
    sch4_type: (state) => state.schedule4.type,
    sch4_stock_shares: (state) => state.schedule4.stock_shares,
    sch4_value_carried: (state) => state.schedule4.value_carried,
    sch4_listed_mkt: (state) => state.schedule4.listed_mkt,
    sch4_listed_mkt_amt: (state) => state.schedule4.listed_mkt_amt,
    sch4_unlisted_value: (state) => state.schedule4.unlisted_value,
    sch4_unlisted_value_amt: (state) => state.schedule4.unlisted_value_amt,
    sch4_unlisted_value_yrly_inc: (state) => state.schedule4.unlisted_value_yrly_inc,

    num_of_sch5: (state) => state.num_of_sch5,
    schedule5: (state) => state.schedule5,
    sch5_policy_amt: (state) => state.schedule5.policy_amt,
    sch5_beneficiary: (state) => state.schedule5.beneficiary,
    sch5_cash: (state) => state.schedule5.cash,
    sch5_loan: (state) => state.schedule5.loan,
    sch5_net_cash: (state) => state.schedule5.net_cash,

    sch2ValuationReason: (state) => state.sch2ValuationReason,
    sch3DelinquentPaymentReason: (state) => state.sch3DelinquentPaymentReason,
    sch3DelinquentTaxReason: (state) => state.sch3DelinquentTaxReason,
    sch3UnrecordedClaim: (state) => state.sch3UnrecordedClaim,
    sch4HeldSecurities: (state) => state.sch4HeldSecurities,

    business_credit_id: (state) => state.business_credit_id,
    business_credit_owner_id: (state) => state.business_credit_owner_id,

  },
  actions: {
    resetAll({ commit }) {
      commit('setCurrentPage', 0)
      commit('resetPageInProgress', 0)
      commit('setValidatedNavPage', { name: 'personal', isValid: 0 })

      commit('setType', 'commerical-loan')
      commit('setCode', '')
      commit('setCodeType', '')
      commit('setApplicationID', '')
      commit('setJWTToken', '')
      commit('setSubmissionID', '')
      
      commit('setDocumentUrls', [])
      commit('setAdditionalDocumentUrls', [])
      commit('setDocumentUpload', [])
      commit('setAdditionalDocumentUpload', [])
      commit('setUploadedFiles', [])
      commit('setUploadedAdditionalFiles', [])

      // applicant
      commit('setAppEmail', '')
      commit('setAppID', '')
      commit('setAppFirstName', '')
      commit('setAppMiddleInitial', '')
      commit('setAppLastName', '')
      commit('setAppPhoneNumber', '')
      commit('setAppEmail', '')
      commit('setAppEmployer', '')
      commit('setAppOccupation', '')
      commit('setAppSsn', '')
      commit('setAppAddress', '')
      commit('setAppApt', '')
      commit('setAppCity', '')
      commit('setAppStateOrTerri', '')
      commit('setAppZip', '')
      commit('setAppHasCoapp', '')
      commit('setAppSignature', '')
      commit('setAppInitials', '')

      //coapplicant
      commit('setCoAppEmail', '')
      commit('setCoAppFirstName', '')
      commit('setCoAppMiddleInitial', '')
      commit('setCoAppLastName', '')
      commit('setCoAppPhoneNumber', '')
      commit('setCoAppEmail', '')
      commit('setCoAppEmployer', '')
      commit('setCoAppOccupation', '')
      commit('setCoAppSsn', '')
      commit('setCoAppSignature', '')

      //assets
      commit('setAssetsCashInFhb', '')
      commit('setAssetsCashInOther', '')
      commit('setAssetsAccReceivable', '')
      commit('setAssetsDueFromFriends', '')
      commit('setAssetsNotesReceivable', '')
      commit('setAssetsRealEstateResi', '')
      commit('setAssetsRealEstateInv', '')
      commit('setAssetsMarketableSec', '')
      commit('setAssetsRetirementAcc', '')
      commit('setAssetsLifeIns', '')
      commit('setAssetsInvInBus', '')
      commit('setAssetsOtherInv', '')
      commit('setAssetsOtherInvDesc', '')
      commit('setAssetsPersonalProp', '')
      commit('setAssetsAuto', '')
      commit('setAssetsOtherAssets', '')
      commit('setAssetsOtherAssetsDesc', '')
      commit('setAssetsTotalAssets', '')

      //loans
      commit('setLoansToFhb', '')
      commit('setLoansToOther', '')
      commit('setLoansChargeCards', '')
      commit('setLoansAccPayable', '')
      commit('setLoansDueToFriends', '')
      commit('setLoansNotesPayable', '')
      commit('setLoansRealEstateMortg', '')
      commit('setLoansIncomeTaxes', '')
      commit('setLoansOtherTaxes', '')
      commit('setLoansLifeIns', '')
      commit('setLoansOther', '')
      commit('setLoansAuto', '')
      commit('setLoansTotalLiabilities', '')
      commit('setLoansNetWorth', '')
      commit('setLoansTotal', '')

      commit('setBusinessAssociation', '')
      commit('setBusinessAssociationIndicator', '')
      commit('setBusinessAssociationReason', '')

      commit('setExecutedWill', '')
      commit('setExecutedWillIndicator', '')

      commit('setTrust', '')
      commit('setTrustIndicator', '')

      commit('setAssetTrust', '')
      commit('setAssetTrustIndicator', '')
      commit('setAssetTrustTitle', '')

      commit('setContingentLiabilities', '')
      commit('setContingentLiabilitiesIndicator', '')
      commit('setContingentLiabilitiesReason', '')

      commit('setOtherBank', '')
      commit('setOtherBankIndicator', '')
      commit('setOtherBankNature', '')

      commit('setOtherClaims', '')
      commit('setOtherClaimsIndicator', '')
      commit('setOtherClaimsReason', '')

      commit('setBankruptcy', '')
      commit('setBankruptcyIndicator', '')
      commit('setBankruptcyReason', '')

      commit('setIncomeSalary', '')
      commit('setIncomeSalaryDesc', '')
      commit('setIncomeInterest', '')
      commit('setExpenseMortgageResidence', '')
      commit('setExpenseMortgageOther', '')
      commit('setExpenseInstallment', '')
      commit('setIncomeSecurities', '')
      commit('setExpenseIncomeTax', '')
      commit('setIncomeRental', '')
      commit('setExpenseOther', '')
      commit('setIncomeNetIncome', '')
      commit('setIncomeOther', '')
      commit('setIncomeOtherDesc', '')
      commit('setIncomeTotal', '')
      commit('setExpenseTotal', '')

      commit('setNumOfSch1', 1)
      commit('setSchedule1', [
        {
          debtor_name: '',
          amount_due: '',
          payable: '',
          maturity: '',
          remarks: '',
        }
      ])

      commit('setNumOfSch2', 1)
      commit('setSchedule2', [
        {
          property_addr: '',
          date_acquired: '',
          monthly_rental_inc: '',
          title: '',
          valuation: '',
          balance_owed: '',
        }
      ])

      commit('setNumOfSch3', 1)
      commit('setSchedule3', [
        {
          payable: '',
          balance_owed: '',
          interest_rate: '',
          maturity: '',
          monthly_payment: '',
        }
      ])
      
      commit('setNumOfSch4', 1)
      commit('setSchedule4', [
        {
          type: '',
          stock_shares: '',
          value_carried: '',
          listed_mkt: '',
          listed_mkt_amt: '',
          unlisted_value: '',
          unlisted_value_amt: '',
          unlisted_value_yrly_inc: '',
        }
      ])
      
      commit('setNumOfSch5', 1)
      commit('setSchedule5', [
        {
          beneficiary: '',
          policy_amt: '',
          cash: '',
          loan: '',
          net_cash: '',
        }
      ])
      
      commit('setSch2ValuationReason', '')
      commit('setSch3DelinquentPaymentReason', '')
      commit('setSch3DelinquentTaxReason', '')
      commit('setSch3UnrecordedClaim', '')
      commit('setSch4HeldSecurities', '')

    },
    setDocumentUrls({ commit }, urls) {
      commit('setDocumentUrls', urls)
    },
    setAdditionalDocumentUrls({ commit }, urls) {
      commit('setAdditionalDocumentUrls', urls)
    },
    setDocumentUpload({ commit }, value) {
      commit('setDocumentUpload', value)
    },
    setAdditionalDocumentUpload({ commit }, value) {
      commit('setAdditionalDocumentUpload', value)
    },
    setUploadedDocuments({ commit }, value) {
      commit('setUploadedDocuments', value)
    },
    setUploadedDocumentsInit({ commit }, value) {
      commit('setUploadedDocumentsInit', value)
    },
    setUploadedDocumentsReset({ commit }, value) {
      commit('setUploadedDocumentsReset', value)
    },
    setUploadedDocumentsReason({ commit }, value) {
      commit('setUploadedDocumentsReason', value)
    },
    setUploadedDocumentsUploadAttempt({ commit }, value) {
      commit('setUploadedDocumentsUploadAttempt', value)
    },
    setUploadedDocumentsUploadFailed({ commit }, value) {
      commit('setUploadedDocumentsUploadFailed', value)
    },
    setUploadedDocumentsUploadValid({ commit }, value) {
      commit('setUploadedDocumentsUploadValid', value)
    },
    setUploadedFiles({ commit }, value) {
      commit('setUploadedFiles', value)
    },

    setUploadedAdditionalFiles({ commit }, value) {
      commit('setUploadedAdditionalFiles', value)
    },
    setUploadedAdditionalDocuments({ commit }, value) {
      commit('setUploadedAdditionalDocuments', value)
    },
    setUploadedAdditionalDocumentsInit({ commit }, value) {
      commit('setUploadedAdditionalDocumentsInit', value)
    },
    setUploadedAdditionalDocumentsReset({ commit }, value) {
      commit('setUploadedAdditionalDocumentsReset', value)
    },
    setUploadedAdditionalDocumentsReason({ commit }, value) {
      commit('setUploadedAdditionalDocumentsReason', value)
    },
    setUploadedAdditionalDocumentsUploadAttempt({ commit }, value) {
      commit('setUploadedAdditionalDocumentsUploadAttempt', value)
    },
    setUploadedAdditionalDocumentsUploadFailed({ commit }, value) {
      commit('setUploadedAdditionalDocumentsUploadFailed', value)
    },
    setUploadedAdditionalDocumentsUploadValid({ commit }, value) {
      commit('setUploadedAdditionalDocumentsUploadValid', value)
    },
    setType({ commit }, type) {
      commit('setType', type)
    },
    setSubmissionID({ commit }, val) {
      commit('setSubmissionID', val)
    },
    setCurrentPage({ commit }, currentPage) {
      commit('setCurrentPage', currentPage)
      commit('setPageInProgress', currentPage)
    },
    setPageInProgress({ commit }, page) {
      commit('setPageInProgress', page)
    },
    resetPageInProgress({ commit }, page) {
      commit('resetPageInProgress', page)
    },
    setValidatedNavPage({ commit }, page) {
      commit('setValidatedNavPage', page)
    },
    setApplicationID({ commit }, value) {
      commit('setApplicationID', value)
    },
    setJWTToken({ commit }, value) {
      commit('setJWTToken', value)
    },
    setCode({ commit }, value) {
      commit('setCode', value)
    },
    setCodeType({ commit }, value) {
      commit('setCodeType', value)
    },

    // applicant
    setAppID({ commit }, value) {
      commit('setAppID', value)
    },
    setAppFirstName({ commit }, value) {
      commit('setAppFirstName', value)
    },
    setAppMiddleInitial({ commit }, value) {
      commit('setAppMiddleInitial', value)
    },
    setAppLastName({ commit }, value) {
      commit('setAppLastName', value)
    },
    setAppPhoneNumber({ commit }, value) {
      commit('setAppPhoneNumber', value)
    },
    setAppEmail({ commit }, value) {
      commit('setAppEmail', value)
    },
    setAppEmployer({ commit }, value) {
      commit('setAppEmployer', value)
    },
    setAppOccupation({ commit }, value) {
      commit('setAppOccupation', value)
    },
    setAppSsn({ commit }, value) {
      commit('setAppSsn', value)
    },
    setAppAddress({ commit }, value) {
      commit('setAppAddress', value)
    },
    setAppApt({ commit }, value) {
      commit('setAppApt', value)
    },
    setAppCity({ commit }, value) {
      commit('setAppCity', value)
    },
    setAppStateOrTerri({ commit }, value) {
      commit('setAppStateOrTerri', value)
    },
    setAppZip({ commit }, value) {
      commit('setAppZip', value)
    },
    setAppHasCoapp({ commit }, value) {
      commit('setAppHasCoapp', value)
    },
    setAppSignature({ commit }, value) {
      commit('setAppSignature', value)
    },

    setAppInitials({ commit }, value) {
      commit('setAppInitials', value)
    },
    setCoAppFirstName({ commit }, value) {
      commit('setCoAppFirstName', value)
    },
    setCoAppMiddleInitial({ commit }, value) {
      commit('setCoAppMiddleInitial', value)
    },
    setCoAppLastName({ commit }, value) {
      commit('setCoAppLastName', value)
    },
    setCoAppPhoneNumber({ commit }, value) {
      commit('setCoAppPhoneNumber', value)
    },
    setCoAppEmail({ commit }, value) {
      commit('setCoAppEmail', value)
    },
    setCoAppEmployer({ commit }, value) {
      commit('setCoAppEmployer', value)
    },
    setCoAppOccupation({ commit }, value) {
      commit('setCoAppOccupation', value)
    },
    setCoAppSsn({ commit }, value) {
      commit('setCoAppSsn', value)
    },
    setCoAppSignature({ commit }, value) {
      commit('setCoAppSignature', value)
    },

    //assets
    setAssetsCashInFhb({ commit }, value) {
      commit('setAssetsCashInFhb', value)
    },
    setAssetsCashInOther({ commit }, value) {
      commit('setAssetsCashInOther', value)
    },
    setAssetsAccReceivable({ commit }, value) {
      commit('setAssetsAccReceivable', value)
    },
    setAssetsDueFromFriends({ commit }, value) {
      commit('setAssetsDueFromFriends', value)
    },
    setAssetsNotesReceivable({ commit }, value) {
      commit('setAssetsNotesReceivable', value)
    },
    setAssetsRealEstateResi({ commit }, value) {
      commit('setAssetsRealEstateResi', value)
    },
    setAssetsRealEstateInv({ commit }, value) {
      commit('setAssetsRealEstateInv', value)
    },
    setAssetsMarketableSec({ commit }, value) {
      commit('setAssetsMarketableSec', value)
    },
    setAssetsRetirementAcc({ commit }, value) {
      commit('setAssetsRetirementAcc', value)
    },
    setAssetsLifeIns({ commit }, value) {
      commit('setAssetsLifeIns', value)
    },
    setAssetsInvInBus({ commit }, value) {
      commit('setAssetsInvInBus', value)
    },
    setAssetsOtherInv({ commit }, value) {
      commit('setAssetsOtherInv', value)
    },
    setAssetsOtherInvDesc({ commit }, value) {
      commit('setAssetsOtherInvDesc', value)
    },
    setAssetsPersonalProp({ commit }, value) {
      commit('setAssetsPersonalProp', value)
    },
    setAssetsAuto({ commit }, value) {
      commit('setAssetsAuto', value)
    },
    setAssetsOtherAssets({ commit }, value) {
      commit('setAssetsOtherAssets', value)
    },
    setAssetsOtherAssetsDesc({ commit }, value) {
      commit('setAssetsOtherAssetsDesc', value)
    },
    setAssetsTotalAssets({ commit }, value) {
      commit('setAssetsTotalAssets', value)
    },

    //loans
    setLoansToFhb({ commit }, value) {
      commit('setLoansToFhb', value)
    },
    setLoansToOther({ commit }, value) {
      commit('setLoansToOther', value)
    },
    setLoansChargeCards({ commit }, value) {
      commit('setLoansChargeCards', value)
    },
    setLoansAccPayable({ commit }, value) {
      commit('setLoansAccPayable', value)
    },
    setLoansDueToFriends({ commit }, value) {
      commit('setLoansDueToFriends', value)
    },
    setLoansNotesPayable({ commit }, value) {
      commit('setLoansNotesPayable', value)
    },
    setLoansRealEstateMortg({ commit }, value) {
      commit('setLoansRealEstateMortg', value)
    },
    setLoansIncomeTaxes({ commit }, value) {
      commit('setLoansIncomeTaxes', value)
    },
    setLoansOtherTaxes({ commit }, value) {
      commit('setLoansOtherTaxes', value)
    },
    setLoansLifeIns({ commit }, value) {
      commit('setLoansLifeIns', value)
    },
    setLoansOther({ commit }, value) {
      commit('setLoansOther', value)
    },
    setLoansAuto({ commit }, value) {
      commit('setLoansAuto', value)
    },
    setLoansTotalLiabilities({ commit }, value) {
      commit('setLoansTotalLiabilities', value)
    },
    setLoansNetWorth({ commit }, value) {
      commit('setLoansNetWorth', value)
    },
    setLoansTotal({ commit }, value) {
      commit('setLoansTotal', value)
    },


    setBusinessAssociation({ commit }, value) {
      commit('setBusinessAssociation', value)
    },
    setBusinessAssociationIndicator({ commit }, value) {
      commit('setBusinessAssociationIndicator', value)
    },
    setBusinessAssociationReason({ commit }, value) {
      commit('setBusinessAssociationReason', value)
    },

    setExecutedWill({ commit }, value) {
      commit('setExecutedWill', value)
    },
    setExecutedWillIndicator({ commit }, value) {
      commit('setExecutedWillIndicator', value)
    },

    setTrust({ commit }, value) {
      commit('setTrust', value)
    },
    setTrustIndicator({ commit }, value) {
      commit('setTrustIndicator', value)
    },

    setAssetTrust({ commit }, value) {
      commit('setAssetTrust', value)
    },
    setAssetTrustIndicator({ commit }, value) {
      commit('setAssetTrustIndicator', value)
    },
    setAssetTrustTitle({ commit }, value) {
      commit('setAssetTrustTitle', value)
    },

    setContingentLiabilities({ commit }, value) {
      commit('setContingentLiabilities', value)
    },
    setContingentLiabilitiesIndicator({ commit }, value) {
      commit('setContingentLiabilitiesIndicator', value)
    },
    setContingentLiabilitiesReason({ commit }, value) {
      commit('setContingentLiabilitiesReason', value)
    },

    setOtherBank({ commit }, value) {
      commit('setOtherBank', value)
    },
    setOtherBankIndicator({ commit }, value) {
      commit('setOtherBankIndicator', value)
    },
    setOtherBankNature({ commit }, value) {
      commit('setOtherBankNature', value)
    },

    setOtherClaims({ commit }, value) {
      commit('setOtherClaims', value)
    },
    setOtherClaimsIndicator({ commit }, value) {
      commit('setOtherClaimsIndicator', value)
    },
    setOtherClaimsReason({ commit }, value) {
      commit('setOtherClaimsReason', value)
    },

    setBankruptcy({ commit }, value) {
      commit('setBankruptcy', value)
    },
    setBankruptcyIndicator({ commit }, value) {
      commit('setBankruptcyIndicator', value)
    },
    setBankruptcyReason({ commit }, value) {
      commit('setBankruptcyReason', value)
    },
    setIncomeSalary({ commit }, value) {
      commit('setIncomeSalary', value)
    },
    setIncomeSalaryDesc({ commit }, value) {
      commit('setIncomeSalaryDesc', value)
    },
    setIncomeInterest({ commit }, value) {
      commit('setIncomeInterest', value)
    },
    setExpenseMortgageResidence({ commit }, value) {
      commit('setExpenseMortgageResidence', value)
    },
    setExpenseMortgageOther({ commit }, value) {
      commit('setExpenseMortgageOther', value)
    },
    setExpenseInstallment({ commit }, value) {
      commit('setExpenseInstallment', value)
    },
    setIncomeSecurities({ commit }, value) {
      commit('setIncomeSecurities', value)
    },
    setExpenseIncomeTax({ commit }, value) {
      commit('setExpenseIncomeTax', value)
    },
    setIncomeRental({ commit }, value) {
      commit('setIncomeRental', value)
    },
    setExpenseOther({ commit }, value) {
      commit('setExpenseOther', value)
    },
    setIncomeNetIncome({ commit }, value) {
      commit('setIncomeNetIncome', value)
    },
    setIncomeOther({ commit }, value) {
      commit('setIncomeOther', value)
    },
    setIncomeOtherDesc({ commit }, value) {
      commit('setIncomeOtherDesc', value)
    },
    setIncomeTotal({ commit }, value) {
      commit('setIncomeTotal', value)
    },
    setExpenseTotal({ commit }, value) {
      commit('setExpenseTotal', value)
    },

    setNumOfSch1({ commit }, value) {
      commit('setNumOfSch1', value)
    },
    setSchedule1({ commit }, value) {
      commit('setSchedule1', value)
    },
    appendSch1({ commit }, value) {
      commit('appendSch1', value)
    },
    setNumOfSch2({ commit }, value) {
      commit('setNumOfSch2', value)
    },
    setSchedule2({ commit }, value) {
      commit('setSchedule2', value)
    },
    appendSch2({ commit }, value) {
      commit('appendSch2', value)
    },
    setNumOfSch3({ commit }, value) {
      commit('setNumOfSch3', value)
    },
    setSchedule3({ commit }, value) {
      commit('setSchedule3', value)
    },
    appendSch3({ commit }, value) {
      commit('appendSch3', value)
    },
    setNumOfSch4({ commit }, value) {
      commit('setNumOfSch4', value)
    },
    setSchedule4({ commit }, value) {
      commit('setSchedule4', value)
    },
    appendSch4({ commit }, value) {
      commit('appendSch4', value)
    },
    setNumOfSch5({ commit }, value) {
      commit('setNumOfSch5', value)
    },
    setSchedule5({ commit }, value) {
      commit('setSchedule5', value)
    },
    appendSch5({ commit }, value) {
      commit('appendSch5', value)
    },
    SetSch2ValuationReason({ commit }, value) {
      commit('SetSch2ValuationReason', value)
    },
    SetSch3DelinquentPaymentReason({ commit }, value) {
      commit('SetSch3DelinquentPaymentReason', value)
    },
    SetSch3DelinquentTaxReason({ commit }, value) {
      commit('SetSch3DelinquentTaxReason', value)
    },
    SetSch3UnrecordedClaim({ commit }, value) {
      commit('SetSch3UnrecordedClaim', value)
    },
    SetSch4HeldSecurities({ commit }, value) {
      commit('SetSch4HeldSecurities', value)
    },

    setBusinessCreditID({ commit }, value) {
      commit('setBusinessCreditID', value)
    },
    setBusinessCreditOwnerID({ commit }, value) {
      commit('setBusinessCreditOwnerID', value)
    },

  },
  mutations: {
    setDocumentUrls(state, value) {
      state.document_urls = value
    },
    setAdditionalDocumentUrls(state, value) {
      state.additional_document_urls = value
    },
    setDocumentUpload(state, value) {
      state.document_upload_setup = value;
    },
    setAdditionalDocumentUpload(state, value) {
      state.additional_document_upload_setup = value;
    },
    setUploadedDocuments(state, value) {
      state.uploaded_documents[value.index] = {};
      state.uploaded_documents[value.index].file = value.file;
      state.uploaded_documents[value.index].hovered = false;
      state.uploaded_documents[value.index].url = value.url;
    },
    setUploadedDocumentsInit(state, value) {
      state.uploaded_documents[value] = {};
    },
    setUploadedDocumentsReset(state, value) {
      state.uploaded_documents[value.index] = null;
    },
    setUploadedDocumentsReason(state, value) {
      state.uploaded_documents[value.index].reason = value.reason;
    },
    setUploadedDocumentsUploadAttempt(state, value) {
      state.uploaded_documents[value.index].uploadAttempt = value.value;
    },
    setUploadedDocumentsUploadFailed(state, value) {
      state.uploaded_documents[value.index].uploadFailed = value.value;
    },
    setUploadedDocumentsUploadValid(state, value) {
      state.uploaded_documents[value.index].valid = value.value;
    },
    setUploadedFiles(state, value) {
      state.uploaded_files = value;
    },
    setUploadedAdditionalFiles(state, value) {
      state.uploaded_additional_files = value;
    },
    setUploadedAdditionalDocuments(state, value) {
      state.uploaded_additional_documents[value.index] = {};
      state.uploaded_additional_documents[value.index].file = value.file;
      state.uploaded_additional_documents[value.index].hovered = false;
      state.uploaded_additional_documents[value.index].url = value.url;
    },
    setUploadedAdditionalDocumentsInit(state, value) {
      state.uploaded_additional_documents[value] = {};
    },
    setUploadedAdditionalDocumentsReset(state, value) {
      state.uploaded_additional_documents[value.index] = null;
    },
    setUploadedAdditionalDocumentsReason(state, value) {
      state.uploaded_additional_documents[value.index].reason = value.reason;
    },
    setUploadedAdditionalDocumentsUploadAttempt(state, value) {
      state.uploaded_additional_documents[value.index].uploadAttempt = value.value;
    },
    setUploadedAdditionalDocumentsUploadFailed(state, value) {
      state.uploaded_additional_documents[value.index].uploadFailed = value.value;
    },
    setUploadedAdditionalDocumentsUploadValid(state, value) {
      state.uploaded_additional_documents[value.index].valid = value.value;
    },
    setSubmissionID(state, value) {
      state.submission_id = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value;
    },
    resetPageInProgress(state, value) {
      state.pageInProgress = value;
    },
    setPageInProgress(state, value) {
      if (value > state.pageInProgress) {
        state.pageInProgress = value;
      }
    },
    setValidatedNavPage(state, page) {
      switch (page.name) {
        case 'personal':
          state.nav.validated_personal_page = page.isValid;
          break;
        case 'business':
          state.nav.validated_business_page = page.isValid;
          break;
        case 'document':
          state.nav.validated_document_page = page.isValid;
          break;
      }
    },
    setApplicationID(state, value) {
      state.application_id = value;
    },
    setJWTToken(state, value) {
      state.jwt_token = value;
    },
    setType(state, value) {
      state.type = value;
    },
    setCode(state, value) {
      state.code = value
    },
    setCodeType(state, value) {
      state.code_type = value
    },

    // applicant
    setAppID(state, value) {
      state.applicant.id = value;
    },
    setAppFirstName(state, value) {
      state.applicant.first_name = value;
    },
    setAppMiddleInitial(state, value) {
      state.applicant.middle_initial = value;
    },
    setAppLastName(state, value) {
      state.applicant.last_name = value;
    },
    setAppPhoneNumber(state, value) {
      state.applicant.phone_number = value;
    },
    setAppEmail(state, value) {
      state.applicant.email = value;
    },
    setAppEmployer(state, value) {
      state.applicant.employer = value;
    },
    setAppOccupation(state, value) {
      state.applicant.occupation = value;
    },
    setAppSsn(state, value) {
      state.applicant.ssn = value;
    },
    setAppAddress(state, value) {
      state.applicant.current_address = value;
    },
    setAppApt(state, value) {
      state.applicant.current_apt_unit = value;
    },
    setAppCity(state, value) {
      state.applicant.current_city = value;
    },
    setAppStateOrTerri(state, value) {
      state.applicant.current_state_or_territory = value;
    },
    setAppZip(state, value) {
      state.applicant.current_zipcode = value;
    },
    setAppSignature(state, value) {
      state.applicant.signature = value;
    },
    setAppInitials(state, value) {
      state.applicant.initials = value;
    },

    //assets
    setAssetsCashInFhb(state, value) {
      state.assets.cash_in_fhb = value;
    },
    setAssetsCashInOther(state, value) {
      state.assets.cash_in_other = value;
    },
    setAssetsAccReceivable(state, value) {
      state.assets.account_receivable = value;
    },
    setAssetsDueFromFriends(state, value) {
      state.assets.due_from_friends = value;
    },
    setAssetsNotesReceivable(state, value) {
      state.assets.notes_receivable = value;
    },
    setAssetsRealEstateResi(state, value) {
      state.assets.real_estate_residence = value;
    },
    setAssetsRealEstateInv(state, value) {
      state.assets.real_estate_investments = value;
    },
    setAssetsMarketableSec(state, value) {
      state.assets.marketable_securities = value;
    },
    setAssetsRetirementAcc(state, value) {
      state.assets.retirement_accounts = value;
    },
    setAssetsLifeIns(state, value) {
      state.assets.life_insurance = value;
    },
    setAssetsInvInBus(state, value) {
      state.assets.investment_in_business = value;
    },
    setAssetsOtherInv(state, value) {
      state.assets.other_investments = value;
    },
    setAssetsOtherInvDesc(state, value) {
      state.assets.other_investments_desc = value;
    },
    setAssetsPersonalProp(state, value) {
      state.assets.personal_property = value;
    },
    setAssetsAuto(state, value) {
      state.assets.autos = value;
    },
    setAssetsOtherAssets(state, value) {
      state.assets.other_assets = value;
    },
    setAssetsOtherAssetsDesc(state, value) {
      state.assets.other_assets_desc = value;
    },
    setAssetsTotalAssets(state, value) {
      state.assets.total_assets = value;
    },

    //loans
    setLoansToFhb(state, value) {
      state.loans.loans_to_fhb = value;
    },
    setLoansToOther(state, value) {
      state.loans.loans_to_other = value;
    },
    setLoansChargeCards(state, value) {
      state.loans.charge_cards = value;
    },
    setLoansAccPayable(state, value) {
      state.loans.accounts_payable = value;
    },
    setLoansDueToFriends(state, value) {
      state.loans.due_to_friends = value;
    },
    setLoansNotesPayable(state, value) {
      state.loans.notes_payable = value;
    },
    setLoansRealEstateMortg(state, value) {
      state.loans.real_estate_mortgages = value;
    },
    setLoansIncomeTaxes(state, value) {
      state.loans.income_taxes = value;
    },
    setLoansOtherTaxes(state, value) {
      state.loans.other_taxes = value;
    },
    setLoansLifeIns(state, value) {
      state.loans.life_insurance_loan = value;
    },
    setLoansOther(state, value) {
      state.loans.other_liabilities = value;
    },
    setLoansAuto(state, value) {
      state.loans.auto_loans = value;
    },
    setLoansTotalLiabilities(state, value) {
      state.loans.total_liabilities = value;
    },
    setLoansNetWorth(state, value) {
      state.loans.net_worth = value;
    },
    setLoansTotal(state, value) {
      state.loans.total_loans = value;
    },

    setBusinessAssociation(state, value) {
      state.business_association = value;
    },
    setBusinessAssociationIndicator(state, value) {
      state.business_association_indicator = value;
    },
    setBusinessAssociationReason(state, value) {
      state.business_association_reason = value;
    },

    setExecutedWill(state, value) {
      state.executed_will = value;
    },
    setExecutedWillIndicator(state, value) {
      state.executed_will_indicator = value;
    },

    setTrust(state, value) {
      state.trust = value;
    },
    setTrustIndicator(state, value) {
      state.trust_indicator = value;
    },

    setAssetTrust(state, value) {
      state.asset_trust = value;
    },
    setAssetTrustIndicator(state, value) {
      state.asset_trust_indicator = value;
    },
    setAssetTrustTitle(state, value) {
      state.asset_trust_title = value;
    },

    setContingentLiabilities(state, value) {
      state.contingent_liabilities = value;
    },
    setContingentLiabilitiesIndicator(state, value) {
      state.contingent_liabilities_indicator = value;
    },
    setContingentLiabilitiesReason(state, value) {
      state.contingent_liabilities_reason = value;
    },

    setOtherBank(state, value) {
      state.other_bank = value;
    },
    setOtherBankIndicator(state, value) {
      state.other_bank_indicator = value;
    },
    setOtherBankNature(state, value) {
      state.other_bank_nature = value;
    },

    setOtherClaims(state, value) {
      state.other_claims = value;
    },
    setOtherClaimsIndicator(state, value) {
      state.other_claims_indicator = value;
    },
    setOtherClaimsReason(state, value) {
      state.other_claims_reason = value;
    },

    setBankruptcy(state, value) {
      state.bankruptcy = value;
    },
    setBankruptcyIndicator(state, value) {
      state.bankruptcy_indicator = value;
    },
    setBankruptcyReason(state, value) {
      state.bankruptcy_reason = value;
    },

    //income 
    setIncomeSalary(state, value) {
      state.income.salary = value;
    },
    setIncomeSalaryDesc(state, value) {
      state.income.salary_desc = value;
    },
    setIncomeInterest(state, value) {
      state.income.interest = value;
    },
    setIncomeSecurities(state, value) {
      state.income.securities = value;
    },
    setIncomeRental(state, value) {
      state.income.rental = value;
    },
    setIncomeNetIncome(state, value) {
      state.income.net_income = value;
    },
    setIncomeOther(state, value) {
      state.income.other = value;
    },
    setIncomeOtherDesc(state, value) {
      state.income.other_desc = value;
    },
    setIncomeTotal(state, value) {
      state.income.total = value;
    },


    //expense
    setExpenseMortgageResidence(state, value) {
      state.expense.mortgage_residence = value;
    },
    setExpenseMortgageOther(state, value) {
      state.expense.mortgage_other = value;
    },
    setExpenseInstallment(state, value) {
      state.expense.installment = value;
    },
    setExpenseIncomeTax(state, value) {
      state.expense.income_tax = value;
    },
    setExpenseOther(state, value) {
      state.expense.other = value;
    },
    setExpenseTotal(state, value) {
      state.expense.total = value;
    },

    setNumOfSch1(state, value) {
      state.num_of_sch1 = value;
    },
    setSchedule1(state, value) {
      state.schedule1 = value;
    },
    appendSch1(state, value) {
      state.schedule1.push(value);
    },
    setNumOfSch2(state, value) {
      state.num_of_sch2 = value;
    },
    setSchedule2(state, value) {
      state.schedule2 = value;
    },
    appendSch2(state, value) {
      state.schedule2.push(value);
    },
    setNumOfSch3(state, value) {
      state.num_of_sch3 = value;
    },
    setSchedule3(state, value) {
      state.schedule3 = value;
    },
    appendSch3(state, value) {
      state.schedule3.push(value);
    },
    setNumOfSch4(state, value) {
      state.num_of_sch4 = value;
    },
    setSchedule4(state, value) {
      state.schedule4 = value;
    },
    appendSch4(state, value) {
      state.schedule4.push(value);
    },
    setNumOfSch5(state, value) {
      state.num_of_sch5 = value;
    },
    setSchedule5(state, value) {
      state.schedule5 = value;
    },
    appendSch5(state, value) {
      state.schedule5.push(value);
    },
    SetSch2ValuationReason(state, value) {
      state.sch2ValuationReason = value;
    },
    SetSch3DelinquentPaymentReason(state, value) {
      state.sch3DelinquentPaymentReason = value;
    },
    SetSch3DelinquentTaxReason(state, value) {
      state.sch3DelinquentTaxReason = value;
    },
    SetSch3UnrecordedClaim(state, value) {
      state.sch3UnrecordedClaim = value;
    },
    SetSch4HeldSecurities(state, value) {
      state.sch4HeldSecurities = value;
    },

    setBusinessCreditID(state, value) {
      state.business_credit_id = value;
    },
    setBusinessCreditOwnerID(state, value) {
      state.business_credit_owner_id = value;
    },
  }
}