import auth from './auth_routes.js'
import store from '@/store'

const jwtISS = 'fhb-loans-commercial'

const personalFinancialRoutes = {
  getToken() {
    return store.getters['personalFiStatement/jwt_token']
  },
  getRedirect() {
    return '/personalFi' // TODO change to Personal Financial Getting Started when available
  },
  loadPersonalFinancialRoutes(routes) {
    routes.push(
      {
        path: '/personalFi',
        name: 'Getting Started Personal Fi',
        component: () => import('../views/business/personal_fi_statement/GettingStarted.vue'),
        alias: ['/']
      },
      {
        path: '/personalFi/personalfinancial',
        name: 'PersonalFi Personal Info',
        component: () => import('../views/business/personal_fi_statement/PersonalFi_PersonalInfo.vue'),
      },
      {
        path: '/personalFi/assetsLiabilities',
        name: 'Assets And Liabilities',
        component: () => import('../views/business/personal_fi_statement/Assets_and_Liabilities.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        }
      },
      {
        path: '/personalFi/annualIncomeAndExpenses',
        name: 'Annual Income And Expenses',
        component: () => import('../views/business/personal_fi_statement/Income_and_Expenses.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        }
      },
      {
        path: '/personalFi/documents',
        name: 'PersonalFi-Document-Upload',
        component: () => import('../views/business/personal_fi_statement/DocumentUpload.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        }
      },
      {
        path: '/personalFi/confirmation',
        name: 'Confirmation',
        component: () => import('../views/business/personal_fi_statement/Confirmation.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        }
      },
      {
        path: '/personalFi/submitted',
        name: 'PersonalFi Submitted',
        component: () => import('../views/business/personal_fi_statement/Submitted.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        }
      }
    )
    return routes
  }
}

export default personalFinancialRoutes
