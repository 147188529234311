import { createStore } from 'vuex'
import questionnaireModule from './modules/questionnaire'
import connectToBankerModule from './modules/connect_to_banker'
import businessCreditAppModule from './modules/business_credit_application'
import businessCreditOwnerModule from './modules/business_credit_owner'
import personalFiStatementModule from './modules/personal_financial_statement'

const store = createStore({
  modules: {
    questionnaire: questionnaireModule,
    connectToBanker: connectToBankerModule,
    businessCreditApp: businessCreditAppModule,
    businessCreditOwner: businessCreditOwnerModule,
    personalFiStatement: personalFiStatementModule
  }
})


export default store
