import auth from './auth_routes.js'
import store from '@/store'

const jwtISS = 'fhb-loans-commercial'

const bankerRoutes = {
  getToken() {
    return store.getters['connectToBanker/jwt_token']
  },
  getRedirect () {
    return '/connectToBanker' // TODO change to Banker Getting Started when available
  },
  loadBankerRoutes(routes) {
    routes.push(
      {
        path: '/connectToBanker',
        name: 'Getting Started Connected To Banker',
        component: () => import('../views/business/connect_banker/GettingStarted.vue'),
      },
      {
        path: '/connectToBanker/banker',
        name: 'Personal Information',
        component: () => import('../views/business/connect_banker/PersonalInfo.vue'),
      },
      {
        path: '/connectToBanker/businessInfo',
        name: 'Business Information',
        component: () => import('../views/business/connect_banker/BusinessInfo.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        }
      },
      {
        path: '/connectToBanker/documentUpload',
        name: 'Document Upload',
        component: () => import('../views/business/connect_banker/DocumentUpload.vue'),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        }
      },
      {
        path: '/connectToBanker/submitted',
        name: 'Connect To Banker Submitted',
        component: () => import('../views/business/connect_banker/Submitted.vue'),
      },
    )
    return routes
  }
}

export default bankerRoutes
